






































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Data } from '@/utils/types/WidgetData';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import SalesPackage from '@/models/graphql/SalesPackage';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import HandoutEditModal from '@/components/modals/HandoutEditModal.vue';
import ViewCartModal from '@/components/modals/ViewCartModal.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { BasicTypes } from '@/utils/types/BasicTypes';
import { Getter, namespace } from 'vuex-class';
import { SalesPackageFilter } from '@/graphql/_Filters/SalesPackageFilter';
import FormatHelper from '@/utils/helpers/FormatHelper';
import ImagePreviewModal from '@/components/modals/ImagePreviewModal.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import FileResource from '@/models/graphql/FileResource';
import { BDropdown } from 'bootstrap-vue';
import SalePackageVariantModal from '@/components/modals/SalePackageVariantModal.vue';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import Exhibitor from '@/models/graphql/Exhibitor';

const widgetDispatcherStore = namespace('WidgetDispatcherStore');
const salesPackageStore = namespace('SalesPackageStore');
const permissionManagerStore = namespace('PermissionManagerStore');
const localStorageCartStore = namespace('LocalStorageCartStore');
const exhibitorStore = namespace('ExhibitorStore');

@Component({
  components: {
    SalePackageVariantModal,
    AvatarSoloWidget,
    ImagePreviewModal,
    ViewCartModal,
    HandoutEditModal,
    FontAwesomeComponent,
    ButtonIconComponent,
  },
})
export default class PackageDetailWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({ required: false, default: null })
  private entityCode!: string;

  @Prop({ required: false, default: () => [] })
  private data!: Data[];

  @permissionManagerStore.Getter
  private canManageSales!: (companyUid: string) => boolean;

  @salesPackageStore.Action
  private getWithStocks!: (payload: {
    filter: SalesPackageFilter;
    exhibitorId: string;
  }) => Promise<SalesPackage>;

  @widgetDispatcherStore.Getter
  private fetchMagicArgs!: Record<string, BasicTypes> ;

  @localStorageCartStore.Action
  private setCart!: (cartUid: string) => void;

  @localStorageCartStore.Action('addToCart')
  private addToCartStore!: (payload: {packageUid: string; quantity: number}) => void;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private adminPanelExhibitor!: Exhibitor;

  private FileResourceHelper = FileResourceHelper;

  private noImage = this.salePackage && !this.salePackage?.fileResource;

  private packageStock = 0;

  private get canManageCompanySales(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE)
    && this.featureByKey(FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE).enabled
      && !!this.fetchMagicArgs.companyId
    && this.canManageSales(this.fetchMagicArgs.companyId as string);
  }

  private get salePackage(): SalesPackage | null {
    if (this.data && this.data.length > 0) {
      return this.data[0] as unknown as SalesPackage;
    }
    return null;
  }

  private get isVariant(): boolean {
    return !!((this.salePackage?.parentSalesPackage?.childSalesPackages
        && this.salePackage.parentSalesPackage?.childSalesPackages.length > 0)
      || this.salePackage?.parentSalesPackage?.parentSalesPackage);
  }

  private get salePackageVariants(): SalesPackage[] | null {
    const getOrderedVariants = (variants: SalesPackage[]): SalesPackage[] => variants
      .slice()
      .sort((a, b) => (a.price || 0) - (b.price || 0));

    if (this.salePackage) {
      if (this.salePackage.childSalesPackages && this.salePackage.childSalesPackages.length > 0) {
        const orderedVariants = getOrderedVariants(this.salePackage.childSalesPackages);
        orderedVariants.unshift(this.salePackage);
        return orderedVariants;
      }

      if (this.salePackage.parentSalesPackage?.childSalesPackages) {
        const parentVariants = this.salePackage.parentSalesPackage?.childSalesPackages || [];
        parentVariants.push(this.salePackage.parentSalesPackage);
        const orderedParentVariants = getOrderedVariants(parentVariants);

        const currentIndex = orderedParentVariants.findIndex((variant) => variant.uid === this.salePackage?.uid);
        if (currentIndex !== -1) {
          orderedParentVariants.unshift(...orderedParentVariants.splice(currentIndex, 1));
        }
        return orderedParentVariants.length > 0 ? orderedParentVariants : null;
      }
    }

    return null;
  }

  private get isCompanySet(): boolean {
    return !!(this.fetchMagicArgs.companyId);
  }

  private get canAddToCart(): boolean {
    let canAdd = true;
    // eslint-disable-next-line no-underscore-dangle
    if (this.salePackage?.rank && (!this.salePackage?.rank && this.adminPanelExhibitor._maxSalesPackageRank
      // eslint-disable-next-line no-underscore-dangle
      && this.adminPanelExhibitor._maxSalesPackageRank > this.salePackage?.rank)) {
      canAdd = false;
    }
    if (!this.isCompanySet || this.packageStock === 0 || this.packageStock < -1) {
      canAdd = false;
    }
    return canAdd;
  }

  private get uid(): string | null {
    let r = this.entityCode;
    if (this.entityCode) {
      const matches = this.entityCode.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          r = r.replaceAll(m, this.$route.params[prop]);
        });
        return r;
      }
    }
    return this.entityCode;
  }

  mounted(): void {
    if (this.isCompanySet) {
      this.setCart(this.fetchMagicArgs.companyId as string);
    }
    this.onDataChange();
  }

  created(): void {
    if (this.payload && 'entityCode' in this.payload && this.payload.entityCode) {
      this.setDataConfig([{
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: { uid: this.payload.entityCode },
            type: GqlEntityFilterType.SALES_PACKAGE_FILTER,
          },
          magicArgs: {
            filter: `(exhibitorUid: "${this.fetchMagicArgs.companyId}")`,
          },
        }),
        operation: 'salesPackage',
        fragmentName: 'salesPackageBaseFragment',
        alias: this.storeName,
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing packageCode in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getSrc(fileResource?: FileResource): string {
    if (fileResource) {
      return FileResourceHelper.getFullPath(fileResource, 'w350');
    }
    return '/img/banner/empty.svg';
  }

  @Watch('data')
  private onDataChange(): void {
    // eslint-disable-next-line no-underscore-dangle
    if (this.data && this.data.length && this.data[0]._stock) {
      // eslint-disable-next-line no-underscore-dangle
      this.packageStock = (this.data[0] as unknown as SalesPackage)._stock.quantity;
    }
  }

  private addToCart(): void {
    if (this.uid && this.isCompanySet) {
      this.getWithStocks({
        filter: { uid: this.uid },
        exhibitorId: this.fetchMagicArgs.companyId as string,
      }).then((response: SalesPackage) => {
        // eslint-disable-next-line no-underscore-dangle
        if (response && response._stock) {
          // eslint-disable-next-line no-underscore-dangle
          this.packageStock = response._stock.quantity;
          if ((this.packageStock >= 1 || this.packageStock === -1) && this.uid) {
            this.addToCartStore({
              packageUid: this.salePackage ? this.salePackage.uid : this.uid,
              quantity: 1,
            });
            if (this.packageStock !== -1) {
              this.packageStock -= 1;
            }
            this.$bvModal.show('view-cart-modal');
          }
        }
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private formatCurrency(price: number): string {
    return FormatHelper.formatCurrency((price || 0) / 100);
  }

  private getPackageStatus(): string {
    if (this.salePackage
      && this.salePackage.maximumQuantityPerExhibitor === 1
      // eslint-disable-next-line no-underscore-dangle
      && this.salePackage._isPurchased > 0) {
      return `${this.$t('sales.package-detail.already-purchased')}`;
    }
    if (this.packageStock > 0 || this.packageStock === -1) {
      return `${this.$t('sales.package-detail.in-stock')}`;
    }
    return `${this.$t('sales.package-detail.out-of-stock')}`;
  }

  private onPackageImageClick(): void {
    this.$bvModal.show('image-preview-modal');
  }

  private onShowVariantsClick(): void {
    this.$bvModal.show('variant-switcher-modal');
  }

  private onVariantSelected(variant: SalesPackage): void {
    const param = { ...this.$route.params, packageId: variant.uid };
    if (this.$route.params.packageId !== variant.uid) {
      this.$router.push({
        name: this.$route.name as string,
        params: param,
        query: this.$route.query,
      });
    }
    (this.$refs['variant-switcher'] as BDropdown).hide();
  }
}
